import { useEffect, useRef, useState } from 'react';
import BellIcon from '../../components/shared/icons/BellIcon';
import IntuitaInputBar from '../../components/shared/intuita-search-bar/IntuitaInputBar';
import useOutsideClick from '../../utils/useOutsideClick';
import { useMutation } from '@apollo/client';
import {
  SET_NOTIFICATIONS_CLEARED,
  SET_NOTIFICATIONS_READ,
} from '../../graphql/hasura/s2mutations';
import { cloneDeep } from 'lodash';
import NotificationsSidebarHeader from './NotificationsSidebarHeader';
import NotificationsSidebarTabSwitcher from './NotificationsSidebarTabSwitcher';
import NotificationsSidebarUnreadList from './NotificationsSidebarUnreadList';
import NotificationsSidebarReadList from './NotificationsSidebarReadList';

const NotificationsSidebar = ({
  refetchUserNotifications,
  client,
  login,
  notificationsUser,
  hasRefetched,
  setHasRefetched,
}) => {
  const notificationsDropdownRef = useRef(null);

  const [userNotifications, setUserNotifications] = useState(
    cloneDeep(notificationsUser)
  );

  const [searchValue, setSearchValue] = useState('');
  const [notificationsState, setNotificationsState] = useState('New');
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  useOutsideClick(notificationsDropdownRef, () => {
    setIsNotificationsOpen(false);
  });

  const [curUserNotifications, setCurUserNotifications] = useState(false);

  const [updateNotificationsRead] = useMutation(SET_NOTIFICATIONS_READ, {
    client: client,
  });

  const [updateNotificationsCleared] = useMutation(SET_NOTIFICATIONS_CLEARED, {
    client: client,
  });

  const handleSetupNotifications = () => {
    setUserNotifications(cloneDeep(notificationsUser));
  };

  useEffect(() => {
    handleSetupNotifications();
  }, []);

  useEffect(() => {
    if (hasRefetched) {
      handleSetupNotifications();
      setHasRefetched(false);
    }
  }, [hasRefetched]);

  const isCurrentUserNotifications = () => {
    setCurUserNotifications(!curUserNotifications);
  };

  const markItemsAsCleared = async () => {
    const idsToMarkAsCleared = [];
    notificationsUser.forEach((notification) => {
      if (notification.notification_read !== 'X') {
        idsToMarkAsCleared.push(notification.notification_id);
      }
    });

    try {
      for (const id of idsToMarkAsCleared) {
        await updateNotificationsCleared({
          variables: {
            object: {
              notification_id: id,
              notification_user: login.username,
              status: 'X',
            },
          },
        });
        refetchUserNotifications();
        setTimeout(() => {
          setHasRefetched(true);
        }, 100);
      }
    } catch (error) {
      console.error('[There was an error marking items as cleared]:', error);
    }
  };

  const markItemsAsRead = async () => {
    const idsToMarkAsRead = [];
    notificationsUser.forEach((notification) => {
      if (notification.notification_read === 'N') {
        idsToMarkAsRead.push(notification.notification_id);
      }
    });

    try {
      for (const id of idsToMarkAsRead) {
        await updateNotificationsRead({
          variables: {
            object: {
              notification_id: id,
              notification_user: login.username,
            },
          },
        });
      }
      refetchUserNotifications();
      setTimeout(() => {
        setHasRefetched(true);
      }, 100);
    } catch (error) {
      console.error('[There was an error marking items as read]:', error);
    }
  };

  const markItemAsRead = async (id) => {
    if (
      userNotifications.some(
        (userNotification) =>
          userNotification.notification_id === id &&
          userNotification.notification_read === 'Y'
      )
    ) {
      return;
    }

    try {
      await updateNotificationsRead({
        variables: {
          object: {
            notification_id: id,
            notification_user: login.username,
          },
        },
      });
      refetchUserNotifications();
      setTimeout(() => {
        setHasRefetched(true);
      }, 100);
    } catch (error) {
      console.error('[There was an error marking this item as read]:', error);
    }
  };

  return (
    <div ref={notificationsDropdownRef}>
      <div
        style={{
          zIndex: 999,
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <BellIcon
          onClick={() => {
            refetchUserNotifications();
            setIsNotificationsOpen(!isNotificationsOpen);
          }}
        />
        <div
          style={{ position: 'absolute', top: -15, right: -5, color: 'white' }}
        >
          {notificationsUser.length > 0 &&
            notificationsUser.filter(
              (item) =>
                item.action !== 'SYSTEM' && item.notification_read === 'N'
            ).length}
        </div>
      </div>

      {isNotificationsOpen && (
        <div
          style={{
            position: 'fixed',
            right: '0',
            top: '65px',
            width: '500px',
            height: 'calc(95vh - 20px)',
            background: 'white',
            zIndex: 399,
            overflowY: 'auto',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
          }}
          className='overflow-style'
        >
          <NotificationsSidebarHeader
            curUserNotifications={curUserNotifications}
            isCurrentUserNotifications={isCurrentUserNotifications}
            markItemsAsRead={markItemsAsRead}
            markItemsAsCleared={markItemsAsCleared}
          />

          <NotificationsSidebarTabSwitcher
            notificationsState={notificationsState}
            setNotificationsState={setNotificationsState}
          />

          <div
            style={{
              padding: '1rem',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IntuitaInputBar
              style={{ width: '100%' }}
              icon='search'
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder='Search by user or message...'
            />
          </div>

          {/* UNREAD NOTIFICATIONS */}
          {notificationsState === 'New' && (
            <>
              {notificationsUser &&
                cloneDeep(notificationsUser)
                  .filter(
                    (item) =>
                      item.action !== 'SYSTEM' && item.notification_read !== 'X'
                  )
                  .filter((item) => {
                    if (curUserNotifications) {
                      return item.created_by === login.username;
                    } else {
                      return item;
                    }
                  })
                  .sort(
                    (a, b) => new Date(b.created_ts) - new Date(a.created_ts)
                  )
                  .filter(
                    (notification) =>
                      notification.notification_text
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      notification.created_by
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                  )

                  .map((notification) => {
                    const isRead = notificationsUser?.some(
                      (userNotification) => {
                        return (
                          userNotification.notification_id ===
                            notification.notification_id &&
                          userNotification.notification_read === 'Y'
                        );
                      }
                    );

                    return (
                      <NotificationsSidebarUnreadList
                        notification={notification}
                        markItemAsRead={markItemAsRead}
                        isRead={isRead}
                        key={notification.notification_id}
                      />
                    );
                  })}
            </>
          )}

          {/* CLEARED NOTIFICATIONS */}
          {notificationsState === 'Cleared' && (
            <>
              {notificationsUser &&
                cloneDeep(notificationsUser)
                  .filter(
                    (item) =>
                      item.notification_user === login.username &&
                      item.notification_read === 'X'
                  )
                  .filter(
                    (item) =>
                      item.notification_text
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      item.created_by
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                  )

                  .map((notification) => {
                    return (
                      <NotificationsSidebarReadList
                        notification={notification}
                      />
                    );
                  })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsSidebar;
